import React, { useState, useRef, useEffect } from "react";
import {
  Col,
  ModalHeader,
  Row,
  Form,
  ModalFooter,
  Button,
  Table,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useTheme } from "../../Utils/ThemeContaxt";
import Bin from "../../../image/bin.jpg";
import SaleItemsListModel from "../SelectListModel/SaleItemsListModel";
import { useDispatch, useSelector } from "react-redux";
import { ItemListReduser } from "../../Transition/Reducer/ItemListReduser";
import PurchaseAccountListModel from "../SelectListModel/PurchaseAccountListModel";
import WaitBox from '../../WatingBox/WaitBox'
const ItemPurchaseModal = (props) => {
  const dispatch = useDispatch();
  const { apiLinks } = useTheme();
  const state = useSelector((state) => state.item_list.data);
  const [showWating, setWatingModal] = useState(false);
  const [rows, setRows] = useState([createEmptyRow()]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [saleItemModels, setSaleItemModels] = useState(false);
  const [purchaseAccountM, setPurchaseAccountM] = useState(false);
  const [accountDetail, setAccountDetail] = useState("");
  const [currentRowIndex, setCurrentRowIndex] = useState(null);

  const [totalPackQuantity, setTotalPackQuantity] = useState(0);
  const [totalPurchasePiceOfQuantity, setTotalPurchasePiceOfQuantity] =
    useState(0);
  const [totalBouns, setTotalBouns] = useState(0);
  const [totalBounsValue, setTotalBounsValue] = useState(0);
  const [itemTotalAmount, setItemTotalAmount] = useState(0);
  const [itemTotalAfterDiscountAmount, setItemTotalAfterDiscountAmount] =
    useState(0);

  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);

  const [totalGst, setTotalGst] = useState(0);
  const [totalGstAmount, setTotalGstAmount] = useState(0);
  const [totalNetAmount, setTotalNetAmount] = useState(0);

  const refs = {
    hadAccCodeRef: useRef([]),
    RemarksRef: useRef([]),
    haddescriptionRef: useRef([]),
    // list Ref
    itemCodeRef: useRef([]),
    DiscriptionRef: useRef([]),
    batchNoRef: useRef([]),
    mDateRef: useRef([]),
    exDateRef: useRef([]),
    quantityRef: useRef([]),
    boUnsRef: useRef([]),
    disCountRef: useRef([]),
    gStPerCenTageRef: useRef([]),
    saleRateRef: useRef([]),
  };

  function createEmptyRow() {
    return {
      ItemCode: "",
      itemDescription: "",
      itemPurchasePacking: "",
      itemBatchNo: "",
      itemMgDate: "",
      itemExDate: "",
      itemPurchaseRate: 0,
      itemSaleRate: 0,
      itemOnePackQuantity: 0,
      itemPurchasePack: 0,
      itemPurchasePiceOfQuantity: 0,
      itemBoUns: 0,
      itemBoUnsValue: 0,
      itemTotalAmount: 0,
      itemDisCount: 0,
      itemDisValue: 0,
      itemAmountAfterDiscount: 0,
      itemGstPerCenTage: 0,
      itemGstAmount: 0,
      itemNetAmount: 0,
      itemBoxCode: "",
      itemCategoryCode: "",
      itemCapacityCode: "",
    };
  }
  const clearForm = () => {
    setRows([createEmptyRow()]);
    setAccountDetail("");
    refs.RemarksRef.current.value = "";
    setTotalNetAmount(0);
    setTotalPackQuantity(0);
    setTotalBouns(0);
    setTotalDiscount(0);
    setTotalDiscountAmount(0);
    setTotalGst(0);
    setTotalGstAmount(0);
  };
  const addRow = () => {
    let updatedRows = [...rows];
    // Add new empty row
    updatedRows.push(createEmptyRow());
    setRows(updatedRows);
  };
  const deleteRow = (index) => {
    const newPackQuantity =
      totalPackQuantity - parseFloat(rows[index].itemPurchasePack || 0);
    const newBouns = totalBouns - parseFloat(rows[index].itemBoUns || 0);
    const newPurchasePice =
      totalPurchasePiceOfQuantity -
      parseFloat(rows[index].itemPurchasePiceOfQuantity || 0);

    const newTotalItemAmount =
      itemTotalAmount - parseFloat(rows[index].itemTotalAmount || 0); // const newTotal = totalNetAmount - rows[index].itemNetAmount;
    const newDiscunt =
      totalDiscount - parseFloat(rows[index].itemDisCount || 0);
    const newDiscountValue =
      totalDiscountAmount - parseInt(rows[index].itemDisValue || 0);
    const newTotalAfterDiscount =
      itemTotalAfterDiscountAmount -
      parseInt(rows[index].itemAmountAfterDiscount || 0);
    const newGst = totalGst - parseFloat(rows[index].itemGstPerCenTage || 0);
    const newGstAmount =
      totalGstAmount - parseFloat(rows[index].itemGstAmount || 0);
    const newitemNetAmount =
      totalNetAmount - parseFloat(rows[index].itemNetAmount || 0);
    const newRows = rows.filter((_, i) => i !== index);

    setRows(newRows);
    setTotalPackQuantity(newPackQuantity);
    setTotalPurchasePiceOfQuantity(newPurchasePice);
    setTotalBouns(newBouns);
    setItemTotalAmount(newTotalItemAmount);
    setTotalDiscount(newDiscunt);
    setTotalDiscountAmount(newDiscountValue);
    setItemTotalAfterDiscountAmount(newTotalAfterDiscount);
    setTotalNetAmount(newitemNetAmount);
    setTotalGst(newGst);
    setTotalGstAmount(newGstAmount);
  };
  const handleKeyPress1 = (field, e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      switch (field) {
        case "AccCode":
          if (refs.hadAccCodeRef.current.value === "") {
            setPurchaseAccountM(true);
            setAccountDetail("");
          } else {
            refs.RemarksRef.current.focus();
          }
          break;

        case "Remarks":
          if (refs.RemarksRef.current.value === "") {
            alert("Please Enter Remarks");
          } else {
            refs.itemCodeRef.current[0].focus();
          }
          break;
        default:
          break;
      }
    }
  };

  const handleKeyPress = (index, field, e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      switch (field) {
        case "ItemCode":
          if (!rows[index].ItemCode) {
            setCurrentRowIndex(index);
            setSaleItemModels(true);
          } else {
            refs.batchNoRef.current[index].focus();
          }
          break;
        case "itemBatchNo":
          refs.mDateRef.current[index].focus();
          break;
        case "itemMgDate":
          refs.exDateRef.current[index].focus();
          break;
        case "itemExDate":
          refs.quantityRef.current[index].focus();
          break;
        case "itemPurchasePack":
          if (!rows[index].itemPurchasePack) {
            alert("Please add an Quantity.");
          } else {
            refs.boUnsRef.current[index].focus();
          }
          break;
        case "itemBoUns":
          refs.disCountRef.current[index].focus();
          break;
        case "itemDisCount":
          refs.gStPerCenTageRef.current[index].focus();
          break;

        case "itemGstPerCenTage":
          addRow();
          setTimeout(() => refs.itemCodeRef.current[rows.length].focus(), 0);
          // refs.itemCodeRef.current[index + 1].focus();

          break;
        default:
          break;
      }
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    if (field === "ItemCode") {
      const selectedItem = state.find((item) => item.itm_cod === value);
      if (selectedItem) {
        updatedRows[index] = {
          ...updatedRows[index],

          ItemCode: selectedItem.itm_cod,
          itemDescription: selectedItem.itm_dsc,
          itemPurchasePacking:
            selectedItem.itm_pack + " X " + selectedItem.itm_pack_quantity,
          itemBatchNo: "",
          itemMgDate: "",
          itemExDate: "",
          itemPurchaseRate: selectedItem.itm_purrat,
          itemSaleRate: selectedItem.itm_salerate,
          itemOnePackQuantity: selectedItem.itm_pack_quantity,
          itemPurchasePack: 0,
          itemPurchasePiceOfQuantity: 0,
          itemBoUns: 0,
          itemBoUnsValue: 0,
          itemTotalAmount: 0,
          itemDisCount: 0,
          itemDisValue: 0,
          itemAmountAfterDiscount: 0,
          itemGstPerCenTage: 0,
          itemGstAmount: 0,
          itemNetAmount: 0,
          itemBoxCode: selectedItem.itm_location,
          itemCategoryCode: selectedItem.itm_catg_cod,
          itemCapacityCode: selectedItem.itm_cap_cod,
        };
      } else {
        updatedRows[index] = {
          ...updatedRows[index],
          // ItemCode:'',
          itemDescription: "",
          itemPurchasePacking: "",
          itemBatchNo: "",
          itemMgDate: "",
          itemExDate: "",
          itemPurchaseRate: 0,
          itemSaleRate: 0,
          itemOnePackQuantity: 0,
          itemPurchasePack: 0,
          itemPurchasePiceOfQuantity: 0,
          itemBoUns: 0,
          itemBoUnsValue: 0,
          itemTotalAmount: 0,
          itemDisCount: 0,
          itemDisValue: 0,
          itemAmountAfterDiscount: 0,
          itemGstPerCenTage: 0,
          itemGstAmount: 0,
          itemNetAmount: 0,
          itemBoxCode: "",
          itemCategoryCode: "",
          itemCapacityCode: "",
        };
      }
    }

    if (field === "itemExDate") {
      updatedRows[index].exDate = value;
    }
    if (field === "itemExDate") {
      updatedRows[index].mDate = value;
    }

    if (field === "itemPurchasePack") {
      const OnePackQuantity = parseFloat(
        updatedRows[index].itemOnePackQuantity || 0
      );
      const purchaseQuantityPack = parseFloat(
        updatedRows[index].itemPurchasePack || 0
      );
      const purchaseRate = parseFloat(updatedRows[index].itemPurchaseRate || 0);
      updatedRows[index].itemTotalAmount = purchaseRate * purchaseQuantityPack;
      updatedRows[index].itemNetAmount = purchaseRate * purchaseQuantityPack;
      updatedRows[index].itemPurchasePiceOfQuantity =
        OnePackQuantity * purchaseQuantityPack;
      updatedRows[index].itemAmountAfterDiscount =
        purchaseRate * purchaseQuantityPack;
      updatedRows[index].itemDisCount = 0;
      updatedRows[index].itemDisValue = 0;
      updatedRows[index].itemGstAmount = 0;
      updatedRows[index].itemGstPerCenTage = 0;
      updatedRows[index].itemBoUns = 0;
      updatedRows[index].itemBoUnsValue = 0;
    }
    if (field === "purchaseRate") {
      // const quantity = parseFloat(updatedRows[index].itemQuantity || 0);
      // const purchaseRate = parseFloat(updatedRows[index].itemPurchaseRate || 0);
      // updatedRows[index].itemTAmount = purchaseRate * quantity;
    }
    if (field === "itemDisCount") {
      const itmAmount = updatedRows[index].itemTotalAmount;
      const itmDic = parseFloat(updatedRows[index].itemDisCount || 0);
      const disAmount = (itmAmount / 100) * itmDic;
      updatedRows[index].itemDisValue = parseFloat(disAmount || 0);
      updatedRows[index].itemAmountAfterDiscount = itmAmount - disAmount;
      updatedRows[index].itemNetAmount = itmAmount - disAmount;
      updatedRows[index].itemGstAmount = 0;
      updatedRows[index].itemGstPerCenTage = 0;
    }

    if (field === "itemBoUns") {
      const OnePackQuantity = parseFloat(
        updatedRows[index].itemOnePackQuantity || 0
      );
      const bouns = parseFloat(updatedRows[index].itemBoUns || 0);
      updatedRows[index].itemBoUnsValue = OnePackQuantity * bouns;
    }
    if (field === "itemGstPerCenTage") {
      const itmAmountAfterDiscount = parseFloat(
        updatedRows[index].itemAmountAfterDiscount || 0
      );
      const gstPercen = parseFloat(updatedRows[index].itemGstPerCenTage || 0);
      const gstAmount = (itmAmountAfterDiscount / 100) * gstPercen;
      updatedRows[index].itemGstAmount = gstAmount;
      updatedRows[index].itemNetAmount = itmAmountAfterDiscount + gstAmount;
    }

    setRows(updatedRows);

    let totalsPurchasePack = 0;
    let quantityTotalPice = 0;
    let totalsBouns = 0;
    let totalsBounsValue = 0;
    let itemTotal = 0;
    let totalsDiscount = 0;
    let totalAfterDiscountAmt = 0;
    let totalsDiscountAmount = 0;
    let totalsGst = 0;
    let totalsGstAmount = 0;
    let totalsNetAmount = 0;

    rows.forEach((rowData) => {
      totalsPurchasePack += parseFloat(rowData.itemPurchasePack || 0);
      quantityTotalPice += parseFloat(rowData.itemPurchasePiceOfQuantity || 0);
      totalsBouns += parseFloat(rowData.itemBoUns || 0);
      itemTotal += parseFloat(rowData.itemTotalAmount || 0);
      totalsDiscount += parseFloat(rowData.itemDisCount || 0);
      totalsDiscountAmount += parseFloat(rowData.itemDisValue || 0);
      totalAfterDiscountAmt += parseFloat(rowData.itemAmountAfterDiscount || 0);
      totalsGst += parseFloat(rowData.itemGstPerCenTage || 0);
      totalsGstAmount += parseFloat(rowData.itemGstAmount || 0);
      totalsNetAmount += parseFloat(rowData.itemNetAmount || 0);
      totalsBounsValue = parseFloat(rowData.itemBoUnsValue || 0);
    });
    setTotalPackQuantity(totalsPurchasePack);
    setTotalPurchasePiceOfQuantity(quantityTotalPice);
    setTotalBouns(totalsBouns);
    setItemTotalAmount(itemTotal);
    setTotalDiscount(totalsDiscount);
    setTotalDiscountAmount(totalsDiscountAmount);
    setItemTotalAfterDiscountAmount(totalAfterDiscountAmt);
    setTotalGst(totalsGst);
    setTotalGstAmount(totalsGstAmount);
    setTotalNetAmount(totalsNetAmount);
    setTotalBounsValue(totalsBounsValue)
  };

  const getInvoiceNumber = () => {
    axios
      .get(`${apiLinks}/purchase-invoice-number`)
      .then(function (response) {
        setInvoiceNumber(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getInvoiceNumber();
    dispatch(ItemListReduser({ apiLinks }));
    refs.hadAccCodeRef.current.focus();
  }, []);
  useEffect(() => {
    // Check if accountDetail is an HTMLDivElement
    if (accountDetail instanceof HTMLDivElement) {
      setAccountDetail(""); // Reset to an empty string if it is an HTML element
    } else {
      refs.hadAccCodeRef.current.value = accountDetail.code ?? "";
      refs.haddescriptionRef.current.value = accountDetail.description ?? "";
    }
  }, [accountDetail]);

  const handleSelectItem = (selectedItem) => {
    const newRows = [...rows];
    newRows[currentRowIndex] = {
      ...newRows[currentRowIndex],
      ItemCode: selectedItem.itm_cod,
      itemDescription: selectedItem.itm_dsc,
      itemPurchasePacking:
        selectedItem.itm_pack + " X " + selectedItem.itm_pack_quantity,
      itemBatchNo: "",
      itemMgDate: "",
      itemExDate: "",
      itemPurchaseRate: selectedItem.itm_purrat,
      itemSaleRate: selectedItem.itm_salerate,
      itemOnePackQuantity: selectedItem.itm_pack_quantity,
      itemPurchasePack: 0,
      itemPurchasePiceOfQuantity: 0,
      itemBoUns: 0,
      itemBoUnsValue: 0,
      itemTotalAmount: 0,
      itemDisCount: 0,
      itemDisValue: 0,
      itemAmountAfterDiscount: 0,
      itemGstPerCenTage: 0,
      itemGstAmount: 0,
      itemNetAmount: 0,
      itemBoxCode: selectedItem.itm_location,
      itemCategoryCode: selectedItem.itm_catg_cod,
      itemCapacityCode: selectedItem.itm_cap_cod,
    };
    setRows(newRows);
    setSaleItemModels(false);
    setTimeout(() => refs.batchNoRef.current[currentRowIndex].focus(), 0);
  };

  const ItemPurchase = () => {
    setWatingModal(true);
    axios
      .post(`${apiLinks}/add-Purchase`, {
        AccountCode: refs.hadAccCodeRef?.current?.value,
        AccountDes: refs.haddescriptionRef?.current?.value,
        remarks: refs.RemarksRef.current.value,
        totalPackQuantity: totalPackQuantity,
        totalPurchasePiceOfQuantity: totalPurchasePiceOfQuantity,
        totalBouns: totalBouns,
        totalBounsValue: totalBounsValue,
        itemTotalAmount: itemTotalAmount,
        totalDiscount: totalDiscount,
        totalDiscountAmount: totalDiscountAmount,
        itemTotalAfterDiscountAmount: itemTotalAfterDiscountAmount,
        totalGst: totalGst,
        totalGstAmount: totalGstAmount,
        totalNetAmount: totalNetAmount,
        detail: rows,
      })
      .then(function (response) {
        if (response.data.success) {
          clearForm();
          getInvoiceNumber();
          setWatingModal(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Modal
        {...props}
        keyboard={false}
        // dialogClassName="modal-80w"
        backdrop="static"
        // contentLabel="Account Maintenance Modal"
        // ariaHideApp={false}

        fullscreen
      >
        <ModalHeader className="model-heders" closeButton>
          Item Purchase
        </ModalHeader>
        <Modal.Body>
          <div className="account-maintenance-form">
            <Row className="g-0">
              <Col lg={1} md={2} sm={2}>
                <Form.Label className="form-lable">Invoice</Form.Label>
              </Col>
              <Col lg={2} md={5} sm={10}>
                <Form.Control
                  value={invoiceNumber.num}
                  className="hadear-form-invoice-number"
                  autoComplete="none"
                  placeholder="Invoice Number"
                  type="number"
                />
              </Col>
            </Row>
            <Row className="g-0">
              <Col lg={1} md={2} sm={2}>
                <Form.Label className="form-lable">Account</Form.Label>
              </Col>

              <Col lg={3} md={5} sm={10} style={{ width: "120px" }}>
                <Form.Control
                  onKeyDown={(e) => handleKeyPress1("AccCode", e)}
                  ref={(el) => (refs.hadAccCodeRef.current = el)}
                  className="hadear-form-account-code-filed"
                  autoComplete="none"
                  placeholder="Account Code"
                />
              </Col>
              <Col lg={6} md={10} sm={10}>
                <Form.Control
                  ref={(el) => (refs.haddescriptionRef.current = el)}
                  disabled
                  className="hadear-form-purchase-account-dsc-filed"
                  placeholder="Description"
                ></Form.Control>
              </Col>
            </Row>
            <Row className="g-0">
              <Col lg={1} md={2} sm={2}>
                <Form.Label className="form-lable">Remarks</Form.Label>
              </Col>
              <Col lg={10} md={8} sm={10}>
                <Form.Control
                  ref={(el) => (refs.RemarksRef.current = el)}
                  className="hadear-form-Remarks-filed"
                  placeholder="Remarks"
                  autoComplete="none"
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase(); // Convert input to uppercase
                  }}
                  onKeyDown={(e) => handleKeyPress1("Remarks", e)}
                />
              </Col>
            </Row>
            <div style={{ overflowX: "auto" }}>
              <Table
                maxHeight="20vh"
                style={{ width: "1490px" }}
                bordered
                className="mt-2"
              >
                <thead className="table-hader-color">
                  <tr>
                    <th
                      style={{ width: "20px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      #
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Code
                    </th>
                    <th
                      className="table-hader-color table-hadear-height"
                      style={{ width: "300px" }}
                    >
                      Description
                    </th>
                    <th
                      style={{ width: "80px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Packing
                    </th>
                    <th
                      style={{ width: "80px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Batch
                    </th>
                    <th
                      style={{ width: "80px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      M Date
                    </th>
                    <th
                      style={{ width: "80px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Expiry
                    </th>
                    <th
                      style={{ width: "50px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Rate
                    </th>
                    <th
                      style={{ width: "80px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Quantity
                    </th>
                    <th
                      style={{ width: "65px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Bouns
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Amount
                    </th>
                    <th
                      style={{ width: "60px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Dic %
                    </th>
                    <th
                      style={{ width: "80px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      D Value
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Amount
                    </th>
                    <th
                      style={{ width: "60px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      GST %
                    </th>
                    <th
                      style={{ width: "80px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      GST Value
                    </th>

                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Amount
                    </th>
                    <th
                      style={{ width: "15px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      A
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <td className="table-row-height">{index + 1}</td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height"
                          placeholder="Code"
                          autoComplete="none"
                          value={row.ItemCode}
                          ref={(el) => (refs.itemCodeRef.current[index] = el)}
                          onChange={(e) =>
                            handleInputChange(index, "ItemCode", e.target.value)
                          }
                          onKeyDown={(e) =>
                            handleKeyPress(index, "ItemCode", e)
                          }
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height"
                          placeholder="Description"
                          autoComplete="none"
                          disabled
                          value={row.itemDescription}
                          ref={(el) =>
                            (refs.DiscriptionRef.current[index] = el)
                          }
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) =>
                            handleKeyPress(index, "description", e)
                          }
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          placeholder="Packing"
                          autoComplete="none"
                          value={row.itemPurchasePacking}
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          placeholder="Batch"
                          autoComplete="none"
                          type="number"
                          onFocus={(e) => e.target.select()}
                          value={row.itemBatchNo}
                          ref={(el) => (refs.batchNoRef.current[index] = el)}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "itemBatchNo",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) =>
                            handleKeyPress(index, "itemBatchNo", e)
                          }
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            width: "95px",
                            verticalAlign: "middle",
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          placeholder="mDate"
                          autoComplete="none"
                          type="date"
                          value={row.itemMgDate}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "itemMgDate",
                              e.target.value
                            )
                          }
                          ref={(el) => (refs.mDateRef.current[index] = el)}
                          onKeyDown={(e) =>
                            handleKeyPress(index, "itemMgDate", e)
                          }
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          className="filed-height number-form"
                          placeholder="exDate"
                          type="date"
                          autoComplete="none"
                          value={row.itemExDate}
                          style={{
                            width: "95px",
                            verticalAlign: "middle",
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "itemExDate",
                              e.target.value
                            )
                          }
                          ref={(el) => (refs.exDateRef.current[index] = el)}
                          onKeyDown={(e) =>
                            handleKeyPress(index, "itemExDate", e)
                          }
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          value={row.itemPurchaseRate.toLocaleString()}
                        />
                      </td>
                      <td
                        style={{ border: "1px solid black" }}
                        className="table-row-height"
                      >
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          placeholder="Quantity"
                          onFocus={(e) => e.target.select()}
                          autoComplete="none"
                          type="number"
                          value={row.itemPurchasePack}
                          ref={(el) => (refs.quantityRef.current[index] = el)}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "itemPurchasePack",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) =>
                            handleKeyPress(index, "itemPurchasePack", e)
                          }
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          placeholder="Bouns"
                          autoComplete="none"
                          type="number"
                          value={row.itemBoUns}
                          onFocus={(e) => e.target.select()}
                          ref={(el) => (refs.boUnsRef.current[index] = el)}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "itemBoUns",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) =>
                            handleKeyPress(index, "itemBoUns", e)
                          }
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          disabled
                          className="filed-height number-form"
                          value={row.itemTotalAmount.toLocaleString()}
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          placeholder="Discount"
                          onFocus={(e) => e.target.select()}
                          autoComplete="none"
                          type="number"
                          value={row.itemDisCount}
                          ref={(el) => (refs.disCountRef.current[index] = el)}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "itemDisCount",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) =>
                            handleKeyPress(index, "itemDisCount", e)
                          }
                        />
                      </td>
                      <td className="table-row-height ">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          disabled
                          value={row.itemDisValue.toLocaleString()}
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          disabled
                          value={row.itemAmountAfterDiscount.toLocaleString()}
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          placeholder="GST"
                          onFocus={(e) => e.target.select()}
                          autoComplete="none"
                          type="number"
                          value={row.itemGstPerCenTage}
                          ref={(el) =>
                            (refs.gStPerCenTageRef.current[index] = el)
                          }
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "itemGstPerCenTage",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) =>
                            handleKeyPress(index, "itemGstPerCenTage", e)
                          }
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          disabled
                          value={row.itemGstAmount.toLocaleString()}
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          style={{
                            borderRadius: "0",
                            padding: "0",
                            height: "25px",
                            fontSize: "15px",
                          }}
                          className="filed-height number-form"
                          disabled
                          value={row.itemNetAmount.toFixed(2).toLocaleString()}
                        />
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // width: "20px",
                        }}
                      >
                        <img
                          onClick={
                            rows.length > 1 ? () => deleteRow(index) : undefined
                          }
                          src={Bin}
                          alt="delete"
                          style={{
                            cursor: "pointer",
                            width: "10px",
                            height: "10px",
                            opacity: rows.length === 1 ? 0.5 : 1, // Optional: to visually indicate disabled state
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                  {Array.from({
                    length: Math.max(0, 12 - rows.length),
                  }).map((_, index) => (
                    <tr key={`blank-${index}`} className="table-row-height">
                      {Array.from({ length: 18 }).map((_, colIndex) => (
                        <td
                          className="table-row-height"
                          key={`blank-${index}-${colIndex}`}
                        >
                          &nbsp;
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Table
                maxHeight="20vh"
                style={{ width: "1490px" }}
                bordered
                hover
                className="mt-2"
              >
                <thead className="table-hader-color">
                  <tr>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {"Total Quanty"}
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {totalPackQuantity}
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {"Total Bonus"}
                    </th>
                    <th
                      style={{ width: "150px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {totalBouns}
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {"Total Dic%"}
                    </th>
                    <th
                      style={{ width: "150px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {totalDiscount}
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {"Dic% Amt"}
                    </th>
                    <th
                      style={{ width: "150px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {totalDiscountAmount.toLocaleString()}
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {"Total Gst %"}
                    </th>
                    <th
                      style={{ width: "150px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {totalGst.toFixed(2)}
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {"Gst % Amt"}
                    </th>
                    <th
                      style={{ width: "150px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {totalGstAmount.toLocaleString()}
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {"Net Amt"}
                    </th>
                    <th
                      style={{ width: "150px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {totalNetAmount.toLocaleString()}
                    </th>
                  </tr>
                </thead>
              </Table>
            </div>
          </div>
        </Modal.Body>

        <ModalFooter className="model-fotters">
          <Button
            onClick={() => {
              ItemPurchase();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
      {purchaseAccountM && (
        <PurchaseAccountListModel
          show={purchaseAccountM}
          onHide={() => setPurchaseAccountM(false)}
          setAccountDetail={setAccountDetail}
        />
      )}
       <WaitBox show={showWating} />

      {saleItemModels && (
        <SaleItemsListModel
          show={saleItemModels}
          onHide={() => setSaleItemModels(false)}
          handleSelectItem={handleSelectItem}
          itemData={state && state}
        />
      )}
    </div>
  );
};

export default ItemPurchaseModal;
